import { render, staticRenderFns } from "./EquityBox.vue?vue&type=template&id=716f42e8&scoped=true&"
import script from "./EquityBox.vue?vue&type=script&lang=js&"
export * from "./EquityBox.vue?vue&type=script&lang=js&"
import style0 from "./EquityBox.vue?vue&type=style&index=0&id=716f42e8&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "716f42e8",
  null
  
)

export default component.exports