<template>
  <div id="app">
    <el-container>
      <el-header>
        <div class="nav-box">
          <div class="logo-box" @click="logoClick">
            <div class="logo">
              <img src="./assets/logo.png" alt="" />
            </div>
            <span style="margin-left: 20px">巴巴圈</span>
          </div>
          <div class="nav-list">
            <div class="list-item">
              <div class="item">
                <router-link to="/">首页</router-link>
              </div>
              <div class="item">
                <router-link to="/solution">帮助中心</router-link>
              </div>
              <div class="item">
                <router-link to="/vip">了解VIP</router-link>
              </div>
              <div class="item">
                <router-link to="/aboutUs">关于我们</router-link>
              </div>
            </div>
            <div class="contactUs">
              <el-button type="danger" @click="contactClick"
                >联系我们</el-button
              >
            </div>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <div class="footer-box">
          <div class="join-us">
            <div class="title">立即加入我们，立即获得收益</div>
            <div class="btn-box">
              <el-row>
                <el-button type="danger" @click="contactClick"
                  >立即变现</el-button
                >
                <el-button type="danger" @click="contactClick"
                  >联系我们</el-button
                >
              </el-row>
            </div>
          </div>
          <div class="bottom-box">
            <el-row :gutter="50">
              <el-col :span="7"
                ><div class="grid-content">
                  <div class="title">快速入口</div>
                  <div class="list-item">
                    <router-link to="/">首页</router-link>
                  </div>
                  <div class="list-item">
                    <router-link to="/solution">帮助中心</router-link>
                  </div>
                  <div class="list-item">
                    <router-link to="/VIP">VIP课程</router-link>
                  </div>
                  <div class="list-item">
                    <router-link to="/aboutUs">关于我们</router-link>
                  </div>
                </div></el-col
              >
              <el-col :span="10"
                ><div class="grid-content">
                  <div class="title">联系我们</div>
                  <div class="concat-box">
                    <div class="box-left">
                      <p>商务微信</p>
                      <img src="./assets/image/weChat.png" alt="" />
                    </div>
                    <div class="box-right" style="margin-left: 25px">
                      <p>巴巴圈小程序</p>
                      <img src="./assets/image/xcx.png" alt="" />
                    </div>
                  </div>
                  <p>联系号码：13922342880</p>
                </div></el-col
              >
              <el-col :span="7"
                ><div class="grid-content">
                  <div class="title">友情链接</div>
                </div></el-col
              >
            </el-row>
          </div>
          <div class="record-box">
            <p>
              Copyright@ 2010-{{ newYear }} 广州歌梵图贸易有限公司 All Rights
              Reserved.
              <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                >粤ICP备2020125816号-1</a
              >
            </p>
            <img src="./assets/image/badge.png" alt="" />
          </div>
        </div>
      </el-footer>
    </el-container>
    <div class="icon-box">
      <el-popover
        placement="bottom-end"
        width="160"
        v-model="visible"
        :popper-options="{
          boundariesElement: 'viewport',
          removeOnDestroy: true,
        }"
      >
        <div class="content-box" style="text-align: center">
          <p
            style="
              font-size: 12px;
              font-family: 'Microsoft YaHei-Bold, Microsoft YaHei';
              font-weight: bold;
            "
          >
            客服工作时间
          </p>
          <p
            style="
              font-size: 12px;
              font-family: 'Microsoft YaHei-Regular, Microsoft YaHei';
              font-weight: 400;
            "
          >
            9:00-22:00
          </p>
          <img width="80%" src="./assets/image/weChat.png" alt="" />
          <p
            style="
              font-size: 12px;
              font-family: 'Microsoft YaHei-Regular, Microsoft YaHei';
              font-weight: 400;
            "
          >
            微信扫码联系客服
          </p>
        </div>
        <el-button slot="reference">帮助</el-button>
      </el-popover>
    </div>
    <ContactBox
      :centerDialogVisible="centerDialogVisible"
      @close="close"
    ></ContactBox>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      centerDialogVisible: false,
      visible: false,
      newYear: "",
    };
  },
  created() {
    this.newYear = new Date().getFullYear();
  },
  methods: {
    logoClick() {
      this.$router.replace("/");
    },
    contactClick() {
      this.centerDialogVisible = true;
    },
    close(data) {
      this.centerDialogVisible = data;
    },
  },
};
</script>
<style lang="less">
.el-dialog__title {
  font-size: 24px;
  font-family: "Microsoft YaHei-Bold, Microsoft YaHei";
  font-weight: bold;
  color: #333333;
}
h1 {
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
p {
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
span {
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
div {
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.icon-box {
  position: fixed;
  top: 50vh;
  right: 25px;
  z-index: 999;
  color: #666;
}
.nav-box {
  width: 65%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo-box {
    display: flex;
    align-items: center;
    height: 100%;
    .logo {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav-list {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 400;
    .list-item {
      display: flex;
      align-items: center;
      .item {
        width: 100px;
        text-align: center;
        // padding: 0 2%;
      }
    }
    .contactUs {
      margin-left: 20px;
      .el-button--danger {
        background-color: #ff0017;
        border-color: #ff0017;
        color: #fff;

        &:hover {
          background: #f84f4f;
          border-color: #f84f4f;
          color: #fff;
        }
      }
    }
    a {
      color: #2c3e50;
      text-decoration: none;

      &.router-link-exact-active {
        color: red;
      }
    }
  }
}
.footer-box {
  width: 100%;
  // height: 640px;
  background: #000;
  .join-us {
    width: 100%;
    height: 200px;
    text-align: center;
    background-image: url(./assets/image/download.png);

    .title {
      font-size: 36px;
      font-family: "Microsoft YaHei-Bold, Microsoft YaHei";
      font-weight: bold;
      color: #ffffff;
      line-height: 120px;
    }
    .btn-box {
      .el-button--danger {
        &:hover {
          background: #f84f4f;
          border-color: #f84f4f;
          color: #fff;
        }
        background-color: #ff0017;
        border-color: #ff0017;
        margin: 0 30px;
      }
    }
  }
  .bottom-box {
    width: 50%;
    margin: 0 auto;
    height: 350px;
    padding-top: 100px;
    .grid-content {
      font-family: "Microsoft YaHei-Regular, Microsoft YaHei";
      a {
        text-decoration: none;
        color: #ffffff;
      }
      .title {
        font-size: 16px;
        font-family: "Microsoft YaHei-Bold, Microsoft YaHei";
        font-weight: bold;
        color: #ffffff;
        line-height: 21px;
        letter-spacing: 3px;
        margin-bottom: 10px;
      }
      .list-item {
        font-size: 14px;
        line-height: 50px;
        font-weight: 400;
        color: #cccccc;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #cccccc;
      }
      .concat-box {
        display: flex;
        align-items: center;
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .record-box {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      width: 30px;
      height: 30px;
      margin-left: 15px;
    }
    p {
      font-size: 14px;
      font-family: "Microsoft YaHei-Regular, Microsoft YaHei";
      font-weight: 400;
      color: #dddddd;
    }
    a {
      text-decoration: none;
      color: #2a91ff !important;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
</style>
