<template>
  <div class="hello">
    <div class="title-box">
      <div class="title">强大、创新的产品能力</div>
      <div class="txt">覆盖整个产品生命周期的成长，并提供多类型资源整合</div>
    </div>
    <div class="tabs-box">
      <div class="tabs-title">
        <span
          v-for="(item, index) in tabsList"
          :key="index"
          @click="tabsClick(index)"
          :style="{
            color: tabsIndex == index ? '#FF0017' : '#fff',
            fontWeight: tabsIndex == index ? 'bold' : '400',
            borderBottom: tabsIndex == index ? '3px solid #FF0017' : 'none',
          }"
          >{{ item }}</span
        >
      </div>
      <div class="content-box" v-show="tabsIndex == 0">
        <div class="box-left">
          <div class="left-top">基础变现</div>
          <div class="left-center">
            全新领先的变现平台，为广大的自媒体创业者提供着优质的产品<br />能力，实现收益和体验的双丰收
          </div>
          <div class="tag-box">
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>付费圈子</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>内容打赏/赞赏</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>内容付费</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>内容电商</span>
            </div>
          </div>
          <div class="left-bottom">
            <div class="bottom-btn">
              <el-button type="info" @click="popupClick">立即变现</el-button>
            </div>
            <div class="bottom-btn">
              <el-button type="info" @click="popupClick">了解更多</el-button>
            </div>
          </div>
        </div>
        <!-- <div class="box-right">11</div> -->
        <img
          class="box-right"
          src="../../../assets/image/second/b2.png"
          alt=""
        />
      </div>
      <div class="content-box" v-show="tabsIndex == 1">
        <div class="box-left">
          <div class="left-top">资源合作</div>
          <div class="left-center">
            平台为用户提供优质的合作资源板块，包括了货源，人才，合作<br />，场地等服务，让用户和资源高频利用
          </div>
          <div class="tag-box">
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>货源</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>人才</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>合作</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>场地</span>
            </div>
          </div>
          <div class="left-bottom">
            <div class="bottom-btn">
              <el-button type="info" @click="popupClick">立即变现</el-button>
            </div>
            <div class="bottom-btn">
              <el-button type="info" @click="popupClick">了解更多</el-button>
            </div>
          </div>
        </div>
        <!-- <div class="box-right">11</div> -->
        <img
          class="box-right"
          src="../../../assets/image/second/b3.png"
          alt=""
        />
      </div>
      <div class="content-box" v-show="tabsIndex == 2">
        <div class="box-left">
          <div class="left-top">圈子社交</div>
          <div class="left-center">
            平台为用户提供了大量优质的电商平台案例视频和相对应的电商<br />社交圈能快速找到适合自己的电商交流圈
          </div>
          <div class="tag-box">
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>建圈子</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>案例视频</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>圈子交流</span>
            </div>
          </div>
          <div class="left-bottom">
            <div class="bottom-btn">
              <el-button type="info" @click="popupClick">立即变现</el-button>
            </div>
            <div class="bottom-btn">
              <el-button type="info" @click="popupClick">了解更多</el-button>
            </div>
          </div>
        </div>
        <!-- <div class="box-right">11</div> -->
        <img
          class="box-right"
          src="../../../assets/image/second/b4.png"
          alt=""
        />
      </div>
      <div class="content-box" v-show="tabsIndex == 3">
        <div class="box-left">
          <div class="left-top">减低难度</div>
          <div class="left-center">
            平台有着大量优质的电商技能学习，培训，资源对接，指导交流<br />等方案，让用户轻松创业就业
          </div>
          <div class="tag-box">
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>技能学习</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>课程培训</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>资源对接</span>
            </div>
            <div class="tag-item">
              <img src="../../../assets/image/second/gx.png" alt="" />
              <span>用户交流</span>
            </div>
          </div>
          <div class="left-bottom">
            <div class="bottom-btn">
              <el-button type="info" @click="popupClick">立即变现</el-button>
            </div>
            <div class="bottom-btn">
              <el-button type="info" @click="popupClick">了解更多</el-button>
            </div>
          </div>
        </div>
        <!-- <div class="box-right">11</div> -->
        <img
          class="box-right"
          src="../../../assets/image/second/b1.png"
          alt=""
        />
      </div>
    </div>
    <ContactBox
      :centerDialogVisible="centerDialogVisible"
      @close="close"
    ></ContactBox>
  </div>
</template>

<script>
export default {
  name: "secondBox",
  data() {
    return {
      tabsList: ["用户变现", "资源整合", "圈子社交", "创业就业"],
      tabsIndex: 0,
      centerDialogVisible: false,
    };
  },
  methods: {
    tabsClick(index) {
      this.tabsIndex = index;
    },
    popupClick() {
      this.centerDialogVisible = true;
    },
    close(data) {
      this.centerDialogVisible = data;
    },
  },
};
</script>

<style scoped lang="less">
.hello {
  width: 100%;
  text-align: center;
  padding: 80px 0;
  height: 800px;
  background: #333333;
  .title-box {
    font-size: 36px;
    color: #fff;
    .txt {
      margin: 20px 0;
      font-size: 16px;
      color: #999;
    }
  }
  .tabs-box {
    width: 100%;
    margin: 50px 0;
    .tabs-title {
      border-bottom: 2px solid #666666;
      padding: 0 0 20px 0;

      span {
        margin: 0 30px;
        color: #fff;
        font-size: 16px;
        font-family: "Microsoft YaHei-Regular, Microsoft YaHei";
        font-weight: 400;
        padding: 20px 0;
        border-bottom: 3px solid #ff0017;
      }
    }
    .content-box {
      width: 60%;
      margin: 80px auto;
      background: #fff;
      height: 387px;
      display: flex;
      justify-content: space-around;
      padding: 0 50px;
      .box-left {
        width: 50%;
        text-align: left;
        padding: 50px 0;
        .left-top {
          font-size: 24px;
          font-weight: bold;
          color: #000;
        }
        .left-center {
          white-space: normal;
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          margin: 20px 0;
          color: #999;
        }
        .tag-box {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .tag-item {
            width: 50%;
            margin: 10px 0;
            span {
              margin-left: 10px;
            }
          }
        }
        .left-bottom {
          display: flex;
          align-items: center;
          margin-top: 30px;
          .bottom-btn {
            margin-right: 50px;
            .el-button {
              background-color: #ff0017;
              border-color: #ff0017;
              margin: 0 30px;
              &:hover {
                background: #f84f4f;
                border-color: #f84f4f;
                color: #fff;
              }
            }
          }
        }
      }
      .box-right{
        width: 386px;
        height: 386px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}
</style>
