<template>
  <div
    class="centent"
    :style="{ background: bannerIdx == 1 ? '#DDE2EC' : '#d6e0ed' }"
  >
    <el-carousel
      indicator-position=""
      :autoplay="false"
      arrow="never"
      @change="bannerChange"
      trigger="click"
    >
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <div class="banner-content">
          <div class="left-box">
            <el-button type="danger" disabled>自媒体创作者</el-button>
            <div class="banner-title">{{ item.title }}</div>
            <p class="banner-text" v-html="item.text"></p>
            <div class="btn-box">
              <el-row>
                <el-button type="danger" @click="contactClick"
                  >立即变现</el-button
                >
                <el-button type="danger" @click="contactClick"
                  >了解更多</el-button
                >
              </el-row>
            </div>
          </div>
          <img
            class="banner-img"
            :src="item.url"
            alt=""
            object-fit="scale-down"
          />
          <!-- <el-image
            style="width: 700px; height: 394px; margin-left: 500px"
            src="../../../assets/image/banner1.png"
            :fit="fit"
          ></el-image> -->
        </div>
      </el-carousel-item>
    </el-carousel>
    <ContactBox
      :centerDialogVisible="centerDialogVisible"
      @close="close"
    ></ContactBox>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fit: "scale-down",
      bannerIdx: 0,
      bannerList: [
        {
          title: "合作共赢,携手前进",
          url: require("../../../assets/image/banner1.png"),
          text: "平台通过协同多元的平台伙伴，为自媒体人提供全面且高<br />质量的变现系统：同时为人才资源的整合提供支持，为用<br />户提供更多创业就业的机会和成长空间",
        },
        {
          title: "专注聚合 高效变现",
          url: require("../../../assets/image/banner2.png"),
          text: "提供全面的疑问解答和解决方案，传递业界专业实践<br />帮助创业者降低成本、提高效率、扩大收益",
        },
      ],
      centerDialogVisible: false,
    };
  },
  methods: {
    bannerChange(e) {
      this.bannerIdx = e;
    },
    contactClick() {
      this.centerDialogVisible = true;
    },
    close(data) {
      this.centerDialogVisible = data;
    },
  },
};
</script>
<style scoped>
/deep/ .el-carousel__indicators {
  bottom: 80px;
  left: 21%;
}
/deep/ .el-carousel__button {
  width: 60px;
  height: 5px;
}
/deep/ .is-active .el-carousel__button {
  background: #ff0017;
  opacity: 1;
}
</style>
<style scoped lang="less">
.centent {
  width: 100%;
  height: 540px;
  background: #d6e0ed;

  .el-carousel {
    height: 100%;

    .el-carousel__item {
      height: 540px;

      .banner-content {
        width: 65%;
        height: 100%;
        position: relative;
        margin: 0 auto;

        .left-box {
          position: absolute;
          margin: 80px 0;
          z-index: 2;
          .el-button--danger {
            background-color: #ff0017;
            border-color: #ff0017;
            &:hover {
              background: #f84f4f;
              border-color: #f84f4f;
              color: #fff;
            }
          }
          .banner-title {
            font-size: 36px;
            letter-spacing: 3px;
            font-family: "Microsoft YaHei-Bold, Microsoft YaHei";
            font-weight: bold;
            color: #333333;
            margin: 20px 0;
          }
          .banner-text {
            line-height: 40px;
            letter-spacing: 2px;
            font-size: 24px;
            font-family: "Microsoft YaHei-Regular, Microsoft YaHei";
            font-weight: 400;
            color: #333333;
          }
          .btn-box {
            margin: 30px 0;
            .el-button--danger {
              width: 117px;
              margin-right: 50px;
              // &:hover {
              //   background: #f84f4f;
              //   border-color: #f84f4f;
              //   color: #fff;
              // }
            }
          }
        }
        .banner-img {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 700px;
          height: 394px;
          margin-left: 200px;
        }
      }
    }
  }
}
</style>
