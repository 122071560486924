import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      title:'巴巴圈_电商分销变现系统_私域流量运营_社群裂变_知识付费变现平台_社交电商 新媒体变现 个人IP运营',
      description: '巴巴圈为自媒体人提供了一整套精准的解决方案，通过公私域引流、社群运营、裂变获客、变现，轻松快速搭建运营体系，降低营销成本、快速获益，让天下没有难做的自媒体人。',
      keywords:'巴巴圈,电商私域,引流获客,分销变现,裂变营销,社群运营,知识付费,复购转化,矩阵号'
    }
  },
  {
    path: '/aboutUs',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta:{
      title:'关于我们-巴巴圈',
      description: '巴巴圈为自媒体人提供技术支持，轻松搭建自己的知识变现平台，私域引流、圈子营销、社群运营、知识付费等服务，轻松实现创业就业。',
      keywords:'巴巴圈,电商私域,社群营销,知识变现,裂变,内容付费,知识付费'
    }
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import('../views/solutionView.vue'),
    meta:{
      title:'帮助中心-巴巴圈',
      description: '在使用巴巴圈的过程中如有遇到不明白的地方，在帮助中心查找问题，轻松解决问题。',
      keywords:'巴巴圈,帮助中心,常见问题,我是自媒体人,我是用户,问题解答'
    }
  },
  {
    path: '/VIP',
    name: 'VIP',
    component: () => import('../views/vipView.vue'),
    meta:{
      title:'巴巴圈平台怎么收费_收费标准_VIP价格',
      description: '巴巴圈目前提供三个售价版本，为您清晰展示平台怎么收费、收费标准、巴巴圈半年/ 年卡多少费用信息，用户可通过自身需求自由选择适合自己的版本。',
      keywords:'巴巴圈,平台怎么收费,VIP收费标准,巴巴圈半年/年卡多少钱,合伙人权益'
    }
  },
  {
    path: '/solutionDetail',
    name: 'solutionDetail',
    component: () => import('../views/solutionDetail.vue'),
    meta:{
      title:'帮助中心-详情',
      description:'',
      keywords:'',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
