<template>
  <div class="EquityBox">
    <div class="EquityBox-top">
      <div class="EquityBox-title">入驻权益</div>
      <div class="line"></div>
    </div>
    <div class="content-box">
      <el-row :gutter="20">
        <el-col :span="6" v-for="(item,index) in equityList" :key="index">
          <div class="item-box">
            <img :src="item.url" alt="" />
            <div class="title">{{item.title}}</div>
            <div class="text">{{item.text}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="join-btn" @click="contactClick">立即申请成为自媒体人</div>
    <ContactBox
      :centerDialogVisible="centerDialogVisible"
      @close="close"
    ></ContactBox>
  </div>
</template>
<script>
export default {
  name: "EquityBox",
  props: {},
  data(){
    return {
      centerDialogVisible:false,
      equityList:[{
        url:require('../../../assets/image/equity/e1.png'),
        title:'信任背书',
        text:'提供第三方可靠交易商城',
      },{
        url:require('../../../assets/image/equity/e1.png'),
        title:'分佣体系',
        text:'专属的分佣体系',
      },{
        url:require('../../../assets/image/equity/e1.png'),
        title:'资源合作',
        text:'整合人才资源等服务的高频利用',
      },{
        url:require('../../../assets/image/equity/e1.png'),
        title:'减低成本',
        text:'大量减低自媒体人创业就业门槛',
      }]
    }
  },
  methods: {
    contactClick() {
      this.centerDialogVisible = true;
    },
    close(data) {
      this.centerDialogVisible = data;
    },
  },
};
</script>

<style scoped lang="less">
.EquityBox {
  .EquityBox-top {
    margin: 50px auto;
    text-align: center;
    .EquityBox-title {
      font-size: 40px;
      font-weight: bold;
    }
    .line {
      width: 50px;
      margin: 5px auto;
      text-align: center;
      border: 2px solid red;
    }
  }
  .content-box {
    width: 65%;
    margin: 30px auto;
    padding-top: 30px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    .item-box {
      color: #222;
      padding: 40px 30px;
      border-radius: 4px;
      background: #EFF2F7;
      text-align: center;
      img {
        width: 50px;
        height: 50px;
      }
      .title {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #666;
      }
    }
  }
  .join-btn {
    color: #fff;
    font-size: 14rpx;
    font-weight: 400;
    background: red;
    text-align: center;
    width: 280px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 5rem;
  }
}
</style>
