<template>
  <div class="home">
    <div class="banner-box">
      <bannerBox></bannerBox>
      
    </div>
    <div class="classify-box">
      <HelloWorld></HelloWorld>
    </div>
    <div class="second-box">
        <secondBox></secondBox>
    </div>
    <div class="equity-box">
      <EquityBox></EquityBox>
    </div>
    <div class="equity-box">
      <ProcessBox></ProcessBox>
    </div>
    <div class="case-box">
      <CaseBox></CaseBox>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import bannerBox from './components/bannerBox.vue'
import HelloWorld from './components/HelloWorld.vue'
import secondBox from './components/secondBox.vue'
import EquityBox from './components/EquityBox.vue'
import ProcessBox from './components/ProcessBox.vue'
import CaseBox from './components/CaseBox.vue'
// import {login} from '@/utils/api.js';
export default {
  name: "HomeView",
  components: {
    bannerBox,
    HelloWorld,
    secondBox,
    EquityBox,
    ProcessBox,
    CaseBox
  },
  created() {
    // this.getLogin()
  },
  methods: {
    
  },
};
</script>
<style lang="less" scoped>
.home {
  .case-box{
    width: 100%;
    background: #F8F8F8;
    padding: 50px 0;
  }
}
</style>
