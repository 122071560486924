<template>
  <div class="hello">
    <div class="title-box">
      <div class="title">自媒体创作者所遇到的痛点</div>
      <div class="txt">全面化的资源合作，帮助各类自媒体成长</div>
    </div>
    <div class="classify-box">
      <div class="item-box">
        <div class="item">
          <img class="item-icon" src="../../../assets/image/defect/q1.png" alt="" />
          <div class="item-content">
            <div class="item-title">缺乏好素材，流量低</div>
            <div class="item-text">没有好的素材，就不能剪辑出热门的视频</div>
          </div>
        </div>
        <div class="item">
          <img class="item-icon" src="../../../assets/image/defect/q3.png" alt="" />
          <div class="item-content">
            <div class="item-title">私欲维护差，复购率低</div>
            <div class="item-text">没有优秀的销售就做不好粉丝的私域维护，无法提高复购率。</div>
          </div>
        </div>
      </div>
      <div class="item-box">
        <div class="item">
          <img class="item-icon" src="../../../assets/image/defect/q2.png" alt="" />
          <div class="item-content">
            <div class="item-title">缺乏好产品，用户不买单</div>
            <div class="item-text">没有好的知识付费产品，用户就不可能买单</div>
          </div>
        </div>
        <div class="item">
          <img class="item-icon" src="../../../assets/image/defect/q4.png" alt="" />
          <div class="item-content">
            <div class="item-title">缺乏背书，用户信任感低</div>
            <div class="item-text">没有可担保的第三方交易商城，用户不放心交易。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>
<style scoped lang="less">
.hello {
  width: 100%;
  text-align: center;
  margin: 25px 0;

  .title-box {
    width: 65%;
    margin: 20px auto;
    font-size: 40px;
    color: #000;
    .txt {
      margin: 20px 0;
      font-size: 20px;
      color: #999;
    }
  }
  .classify-box {
    width: 65%;
    margin: 60px auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    .item-box {
      // min-width: 18%;
      // height: 100px;
      .item {
        height: 100%;
        padding: 20px 0;
        display: flex;
        align-items: center;
        .item-icon {
          width: 60px;
          height: 60px;
        }
        .item-content {
          margin-left: 1vw;
          text-align: left;
          line-height: 30px;
          .item-title {
            font-size: 20px;
            font-weight: bold;
            color: #000;
          }
          .item-text {
            font-size: 16px;
            font-weight: 400;
            color: #999;
          }
        }
      }
    }
  }
}
</style>
