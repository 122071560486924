<template>
  <div class="content">
    <el-dialog
      title="联系客服"
      :visible.sync="visible"
      width="20%"
      @close="close"
      center
    >
      <div class="contact-box">
        <img width="50%" src="../assets/image/weChat.png" alt="" />
        <p>打开微信扫一扫，立即添加好友<br />进行沟通</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "contactBox",
  props: {
    centerDialogVisible: Boolean,
  },
  data() {
    return {
        visible: false,
    };
  },
  methods: {
    contactClick() {
      this.centerDialogVisible = true;
    },
    close(){
        this.$emit('close',false)
    }
  },
  watch: {
    centerDialogVisible: {
      handler(newVal, oldVal) {
        this.visible = newVal;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="less" scoped>
.contact-box {
  width: 100%;
  text-align: center;
  p {
    font-size: 16px;
    font-family: "Microsoft YaHei-Regular, Microsoft YaHei";
    font-weight: 400;
    color: #333333;
  }
}
</style>
