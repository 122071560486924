<template>
  <div class="ProcessBox">
    <div class="ProcessBox-top">
      <div class="ProcessBox-title">入驻流程</div>
      <div class="line"></div>
    </div>
    <div class="content-box">
      <div class="content">
        <div class="item-box">
          <div class="sn">
            <img src="../../../assets/image/process/next.png" alt="" />
            <img src="../../../assets/image/process/b1.png" alt="" />
          </div>
          <p>购买会员等级</p>

          <div class="title">在会员中心根据需求购买相应的会员等级并缴纳费用</div>
        </div>
        <div class="item-box">
          <div class="sn">
            <img src="../../../assets/image/process/next.png" alt="" />
            <img src="../../../assets/image/process/b2.png" alt="" />
          </div>
          <p>认证用户信息</p>
          <div class="title">平台对购买会员的用户进行认证并查看相关信息</div>
        </div>
        <div class="item-box">
          <div class="sn">
            <img src="../../../assets/image/process/next.png" alt="" />
            <img src="../../../assets/image/process/b3.png" alt="" />
          </div>
          <p>用户审核</p>
          <div class="title">根据认证会员用户的信息，进行审核</div>
        </div>
        <div class="item-box">
          <div class="sn">
            <img src="../../../assets/image/process/next.png" alt="" />
            <img src="../../../assets/image/process/b4.png" alt="" />
          </div>
          <p>完成入驻</p>
          <div class="title">通过：完成入驻可开展相关工作<br />
            <span style="color:#FF0017">驳回：返还费用</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProcessBox",
  props: {},
  methods: {},
};
</script>
<style scoped lang="less">
.ProcessBox {
  margin-top: 100px;
  .ProcessBox-top {
    margin: 0 auto;
    text-align: center;
    .ProcessBox-title {
      font-size: 40px;
      font-weight: bold;
    }
    .line {
      width: 70px;
      height: 3px;
      background: #ff0017;
      margin: 0 auto;
      border-radius: 10px;
      margin-top: 15px;
    }
  }
  .content-box {
    background: #eff2f7;
    margin: 30px 0;
    margin-top: 80px;
    .content {
      width: 60%;
      margin: 0 auto;
      padding: 60px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .item-box {
      width: 15%;
      padding: 20px 20px;
      color: #333;
      border-radius: 5px;
      .sn {
        display: flex;
        align-items: center;
        img {
          line-height: 0;
        }
      }
      p {
        margin-top: -10px;
        line-height: 0;
        font-size: 24px;
        font-family: "Microsoft YaHei-Bold, Microsoft YaHei";
        font-weight: bold;
        color: #222222;
      }
      .title {
        font-size: 16px;
        font-family: "Microsoft YaHei-Regular, Microsoft YaHei";
        font-weight: 400;
        line-height: 30px;
        color: #666666;
        margin-top: 25px;
        min-height: 90px;
      }
    }
  }
}
</style>
