<template>
  <div class="EquityBox">
    <div class="EquityBox-top">
      <div class="EquityBox-title">客户案例</div>
      <p>
        从集团公司到初创团队，符合多类型不同的企业需要；以下客户信息由客服沟通并确认，但因双方人员变动，若有异议，请联系客服
      </p>
    </div>
    <div class="content-box">
      <el-row :gutter="20" type="flex" class="row-bg" justify="center">
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/01.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/02.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/03.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/04.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/05.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/06.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/07.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/08.png" alt="" /></div
        ></el-col>
      </el-row>
      <el-row :gutter="20" class="row-bg">
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/09.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/10.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/11.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/12.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/13.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/14.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/15.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/16.png" alt="" /></div
        ></el-col>
      </el-row>
      <el-row :gutter="20" type="flex" class="row-bg" justify="end">
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/17.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/18.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/19.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/20.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/21.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/22.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/23.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple">
            <img src="../../../assets/image/caseList/24.png" alt="" /></div
        ></el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "EquityBox",
  props: {},
  methods: {},
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-col {
  width: 172px;
  height: 72px;
  border-radius: 4px;
  /* background: #fff; */
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
<style scoped lang="less">
.EquityBox {
  margin-top: 20px;
  .EquityBox-top {
    margin: 0 auto;
    text-align: center;
    .EquityBox-title {
      font-size: 36px;
      font-weight: bold;
    }
    p {
      margin: 25px 0;
      font-size: 16px;
      font-family: 'Microsoft YaHei-Regular, Microsoft YaHei';
      font-weight: 400;
      color: #666666;
    }
  }
  .content-box {
    width: 80%;
    margin: 0 auto;
    // padding: 20px 17.5%;
    padding-top: 30px;
    img {
      width: 100%;
    }
  }
}
</style>
