import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/utils/request'
import ContactBox from '@/components/contactBox'
//1 eui组件
import ElementUI from 'element-ui';
// 2 eui 样式
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false;
Vue.component('ContactBox',ContactBox)
Vue.prototype.$axios = axios
// 3 注册给vue
Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
  if(Object.keys(to.meta).length>0 && to.matched.length>0){
    let this_meta=to.matched[to.matched.length-1].meta
    if(this_meta.title)document.title=this_meta.title
    let head = document.getElementsByTagName('head');
    let meta_keyword=document.createElement('meta');
    if(document.querySelector('meta[name="keywords"]')){
      document.querySelector('meta[name="keywords"]').setAttribute('content',this_meta.keywords)
    }else{
      meta_keyword.setAttribute('name','keywords')
      meta_keyword.setAttribute('content',this_meta.keywords)
      head[0].appendChild(meta_keyword)
    }
    let meta_description=document.createElement('meta');
    if(document.querySelector('meta[name="description"]')){
      document.querySelector('meta[name="description"]').setAttribute('content',this_meta.description)
    }else{
      meta_description.setAttribute('name','description')
      meta_description.setAttribute('content',this_meta.description)
      head[0].appendChild(meta_description)
    }
  }
  next()
});

new Vue({
  el: '#app',	
  router,
  store,
  render: h => h(App)
}).$mount('#app')
